// import Script from "next/script";
import { Flex, Button, Text } from '@chakra-ui/react';
import Link from 'next/link';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const Custom404 = () => {
  const seo = {};
  return (
    <Layout withAppNavBar={false}>
      <Seo seo={seo} />
      <Flex
        h="100vh"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text style={{ fontSize: 90, fontWeight: '#0b0b0b' }}>404</Text>
        <Text style={{ fontSize: 24 }}>This page could not be found.</Text>
        <Link href={'/'} style={{ textDecoration: 'none' }}>
          <Button
            name="go"
            type="submit"
            backgroundColor={'brand.900'}
            shadow={'md'}
            color={'white'}
            _hover={{
              opacity: 0.7,
            }}
            fontWeight={'medium'}
          >
            Go Home
          </Button>
        </Link>
      </Flex>
    </Layout>
  );
};

export default Custom404;
